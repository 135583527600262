export const DSID = "d372cf0a-d449-4f73-92d4-9a279d35caf1";
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";


export const APPLICATION_PM_STATUS_GROUP = "pm_status";




export const ApplicationStatus_NotSubmitted =  "f9e98b61-e5d5-4ae5-9da5-c7a5f1aef113" ;
export const ApplicationStatus_Submitted =  "61c50976-cf9d-4e2a-91c0-e540fba96b56" ;
export const ApplicationStatus_PendingFeeSending =  "d8e89072-5d50-4bad-81eb-34eb1beb5931" ;
export const ApplicationStatus_PendingFeePayment =  "7e405e72-25e9-46e9-bb20-9237e6c9f1c7" ;
export const ApplicationStatus_PendingAcceptance =  "4271a843-a5c7-488e-bc41-6afebf9cb5a0" ;
export const ApplicationStatus_Accepted =  "fa5e4006-e5cc-43bb-9834-624f554fc75a" ;

export const ApplicationStatus_GeneratingBankAccounts =  "c619615a-01d5-4083-86ee-38fe013f183e" ;
export const ApplicationStatus_GeneratingContracts =  "f9bb4067-81f7-482a-9f7e-7f424f9b3ba0" ;
export const ApplicationStatus_GeneratingDeposit =  "583dfe0d-9651-4838-962f-f8475361974a" ;
export const ApplicationStatus_RemoveExistingContracts =  "61b01eca-59b0-468e-b4b8-db6d495bdcb2" ;
export const ApplicationStatus_GeneratingOneTimePayment =  "e360cf3b-2fa8-48ed-8bee-cac994c0c535" ;

export const ApplicationStatus_PendingTenantSignature =  "b15eade9-ac5e-41c5-8ed7-3e13b6cef215" ;
export const ApplicationStatus_PendingTenantDeposit =  "d4babfe3-de4a-4986-880a-c2cfb58fb1f1" ;
export const ApplicationStatus_PendingManagerSignature =  "00008f05-a661-47c6-b812-6c1f66e2b1a8" ;
export const ApplicationStatus_PendingLease =  "a57ac59e-1a33-4ab8-ac5d-4985194500ab" ;
export const ApplicationStatus_Leased =  "c3877def-868c-41f4-a4da-ac1359239e22" ;
export const ApplicationStatus_Completed =  "e5e80625-44c3-4129-a3c1-222b28f3f564" ;
export const ApplicationStatus_Expired =  "04962f22-0fed-4e15-94db-06e901a5b00a" ;
export const ApplicationStatus_PendingDecline =  "e4e0780d-ad3b-421e-a213-4eaac4fc5ebe" ;
export const ApplicationStatus_Declined =  "cd01c6d9-91eb-44cf-bee7-4d36f24e11d1" ;
export const ApplicationStatus_MoveOut =  "73690d33-0f18-46ce-962e-415fd790b2aa" ;
export const ApplicationStatus_PendingCombining =  "e64f8c3e-5982-4cab-8c87-aadfdac9c523" ;
export const ApplicationStatus_Error =  "c31c690d-b50d-4855-a294-59a38c9ff26b" ;
export const ApplicationStatus_Blocked =  "75a5f848-300e-4be7-811d-fedda4863eac" ;


export const ApplicationStatus_Leasing =[
    ApplicationStatus_NotSubmitted,
    ApplicationStatus_Submitted,
    ApplicationStatus_PendingFeeSending,
    ApplicationStatus_PendingFeePayment,
    ApplicationStatus_PendingAcceptance,
    ApplicationStatus_Accepted,
    ApplicationStatus_GeneratingBankAccounts,
    ApplicationStatus_GeneratingContracts,
    ApplicationStatus_GeneratingDeposit,
    ApplicationStatus_RemoveExistingContracts,
    ApplicationStatus_GeneratingOneTimePayment,
    ApplicationStatus_PendingTenantSignature,
    ApplicationStatus_PendingTenantDeposit,
    ApplicationStatus_PendingManagerSignature,
    ApplicationStatus_PendingLease,
    ApplicationStatus_Blocked,
    ApplicationStatus_Error,
    ApplicationStatus_PendingCombining
]